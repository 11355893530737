@import '../variables';

/* GENERAL */

body{
    background:$body_background;
    color: $body_color;
}

.wrapper{
    background:$content_background;
}

h2, .h2, .title_section {
    border-bottom-color: $titre_border_color;
    color: $titre_color;
}

.links_p a {
    color: $link_color;
}

/* HEADER */

header.banniere{
    background-color:$header_fond;
}

.slogan {
    color: $slogan_color;
}

/* BOUTONS */

.bouton, .button, .links_p .bouton, .links_p .button, input[type="submit"], .form-newsletter input[type="submit"], button,
.addbasket, .links_p .addbasket, .addmsglo, .links_p .addmsglo,  .wrapper a.slider-see-more,
body .template_fiche_produit_3 #fiche-produit .wrap-description .side-tabs a {
    border-color: $boutons_border_color;
    background-color: $boutons_background;
    color: $boutons_color;
    background-image: none !important;
    &:hover, &:focus, &.active {
        color: $boutons_color_hover;
        border-color: $boutons_border_color_hover;
        background-color: $boutons_background_hover;
        &::before, &::after {
            color: inherit;
        }
    }
}

.produits .zoom::before, .wrapper a.addcommentaires, .accueil_diaporama_template .content-slide-text > a.addcommentaires, .radio_unique{
    border-color: $boutons_border_color;
    background-color: $boutons_background;
    color: $boutons_color;
}
.produits .zoom:hover::before{
    color: $boutons_color_hover;
    border-color: $boutons_border_color_hover;
    background-color: $boutons_background_hover;
}


/* MENU */

.menu.navigation_p.menu-principal{
    background: $fond_barre_menu;
}

.wrapper-content .menu.navigation_p{
    background: $fond_barre_menuS;
}

.wrapper-content .menu {
    border-bottom-color: $border_menuS;
}

//Menu principal

#nav-principal{
    .navigation_button_p{
        color:$menu_color;
        &::after{
            background:$menu_color;
        }
        .navigation_link_p{
            color:$menu_color;
        }
        &:hover, &.actif, &:focus, .sousmenu_ouvert, .menu_deploye{
            background:$menu_background_hover;
            color:$menu_color_hover;
            > .navigation_link_p{
                color:$menu_color_hover;
            }
        }
    }
    .sous_navigation_button_p{
        color:$menu_color_sous;
        background:$menu_background_sous;
        .sous_navigation_link_p{
            color:$menu_color_sous;
        }
        &:hover, &.actif, &:focus, .sousmenu_ouvert{
            color:$menu_color_sous_hover;
            background:$menu_background_sous_hover;
            > .sous_navigation_link_p{
                color:$menu_color_sous_hover;
            }
        }
    }
}

@media #{$medium-and-down} {
    #nav-principal > ul{
        background: $fond_barre_menu;
    }
}

//Menu secondaire

#nav-secondary{
    .navigation_button_p{
        color:$menuS_color;
        .navigation_link_p{
            color:$menuS_color;
        }
        &:hover, &.actif, &:focus, .sousmenu_ouvert, .menu_deploye{
            background:$menuS_background_hover;
            color:$menuS_color_hover;
            &::after{
                background:$fond_barre_menuS;
            }
            > .navigation_link_p{
                color:$menuS_color_hover;
            }
        }
    }
    .sous_navigation_button_p{
        color:$menuS_color;
        background:$fond_barre_menuS;
        .sous_navigation_link_p{
            color:$menuS_color;
        }
        &:hover, &.actif, &:focus, .sousmenu_ouvert{
            color:$menuS_color_hover;
            background:$menuS_background_hover;
            > .sous_navigation_link_p{
                color:$menuS_color_hover;
            }
        }
    }
}

//Fil d'ariane

nav.arianne {
    ul {
        &::before{
            background-color:$ariane_border;
        }
        color:$ariane_color;
        a{
            color: $ariane_color;
        }
    }
    li{
        &::before, &::after{
            background-color:$ariane_border;
        }
        &::after{
            box-shadow: 0 0 0 4px rgba($ariane_border, .1);
        }
        &:last-child::after{
            box-shadow: 0 0 0 6px rgba($ariane_border, .4);
        }
        &:hover::after{
            box-shadow: 0 0 0 6px rgba($ariane_border, .4);
        }
    }
}

/* SLIDER */

#main-conteneur .accueil_diaporama_template,
#main-conteneur .accueil_boutique_template_1,
#main-conteneur .template_fiche_produit_3{
    .bx-controls {
        a.bx-prev, a.bx-next {
            &::after{
                color:$slider_color;
                background:$slider_background;
            }
            &:hover{
                &::after{
                    color:$slider_color_hover;
                    background:$slider_background_hover;
                }
            }
        }
    }
    .bx-pager-item{
        a.bx-pager-link{
            color:$slider_color !important;
            background:$slider_background !important;
            &.active, &:hover, &:focus{
                color:$slider_color_hover !important;
                background:$slider_background_hover !important;
            }
        }
    }
}
.accueil_diaporama_template{
    .slide .slide-text{
        p{
            background:$mask_fond;
            color:$mask_color;
        }
    }
}

.template_fiche_produit{
    &.template_fiche_produit_1 #fiche-produit{
        .wrap-images .previews{
            .previous, .next {
                &::after{
                    color:$slider_color;
                    background:$slider_background;
                }
                &:hover{
                    &::after{
                        color:$slider_color_hover;
                        background:$slider_background_hover;
                    }
                }
            }
        }
    }
    &.template_fiche_produit_2 #fiche-produit{
        .wrap-description .h4{
            color:$fiches_description_color !important;
            border-color: $fiches_description_color !important;
        }
    }
    &.template_fiche_produit_3 #fiche-produit{
        .wrap-description .h4{
            color:$fiches_description_color !important;
            border-color: $fiches_description_color !important;
        }
    }
}


/* BOUTIQUE / GALERIE */

.produits .productImageWrap > a:last-child::after, .galerie .mask{
    background:$mask_fond;
    color:$mask_color;
}

/* BOUTIQUE */

.produits{
    background:$produits_fond;
    color:$produits_color;
    border:2px solid $produits_border;
    .nomprod, .desc {
        color: $produits_color;
    }
    .prix{
        .oldprix{
            color:$produits_oldprix;
        }
    }
    .attr{
        select {
            border-color: $select_border;
            background: $select_background;
            color: $select_color;
        }
    }
    .productImageWrap{
        &::before{
            background-color:$produits_border;
        }
    }
}

select, #fiche-produit select.form-control, .template_contact_4 .tmp4blocC select{
    &, &:focus{
        border-color: $select_border;
        background: $select_border;
        color: $select_color;
    }
}

/* PRODUITS / FICHES PRODUITS */

.inside-remise{
    background:$remise_background;
    color:$remise_color;
}

.produit_etiquette{
    &, &2, &3{
        background:$etiquette_background;
        color:$etiquette_color;
        h3, .h3{
            color:$etiquette_color;
        }
    }
    &::after{
        border-color:$etiquette_background rgba(65,129,217,0) rgba(65,129,217,0)
    }
    &2::after{
        border-color: rgba(0, 0, 0, 0) $etiquette_background rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    }
}

/* FICHE PRODUIT */

#fiche-produit{
    .block-fiche-titre-brand{
        background:$fiches_titres_background;
        color:$fiches_titres_color;
        .block-fiche-brand a, .block-fiche-titre h1, .brand a{
            color:$fiches_titres_color;
        }
    }
    .wrap-details{
        h4, .h4{
            border-bottom:3px solid $fiches_h4_border;
        }
    }
    .wrap-description{
        background: $fiches_description_background;
        color: $fiches_description_color;
        > label:first-child{
            color: $fiches_description_color !important;
        }
        .note-produit{
            a{
                color:inherit !important;
            }
        }
        .prix{
            border-bottom-color:$fiches_prix_border;
            .oldprix{
                color: red;
            }
            .prixprod {
                color: $fiches_prixprod;
            }
        }
        .sociaux {
            border-top-color:$fiches_sociaux_border;
        }
    }
}

/* GALERIE */

.template_album_1,
.template_album_2,
.template_album_3{
    .galerie{
        background:$mask_fond;
        .commentP p{
            background:$mask_fond;
            color:$mask_color;
        }
    }
}
.template_album_4,
.template_album_5{
    .galerie{
        background:$mask_fond;
        .commentP p{
            color:$mask_color;
        }
    }
}

/* LIVRE D'OR */

.template_livreor{
    &.template_livreor_1{
        .message_lo{
            background:$lo_background;
            color:$lo_color;
            &, &::before, .note{
                color:$lo_color;
            }
            h4, .h4, .web{
                color: $lo_color;
            }
            a{
                color:$lo_color;
            }
        }
    }
    &.template_livreor_2{
        .message_lo{
            .IDLO{
                background:$lo_background;
                color:$lo_color;
            }
            .contenuMsgLO{
                background:transparent;
                border-color:$lo_background;
                .blcFleche::before, .note{
                    background: $lo_background;
                    border-color:$lo_background;
                    color:$lo_color;
                }
            }
            &:hover{
                .IDLO{
                    background:$lo_background;
                    color:$lo_color;
                }
            }
        }
    }
    &.template_livreor_3{
        .message_lo {
            background: $lo_background;
            color: $lo_color;
            a{
                color: $lo_color;
            }
        }
    }
}

/* CONTACT */

form, .template_contact form, #fiche-produit form{
    .form-group{
        .formError ~ {
            input[type="text"]:not(.button):not([type="file"]), textarea, input[type="password"]{
                border-color:red !important;
            }
        }
        input[type="text"]:not(.button):not([type="file"]), textarea, input[type="password"]{
            border-color:$form_input_border;
            background:$form_input_background;
            color:$form_input_color;
            &:focus, &:hover, &.filled{
                border-color:$form_input_border_hover;
            }
        }
        .groupe-radio, .groupe-checkbox {
            &:hover{
                label {
                    &::before {
                        border-color:$form_input_border_hover;
                    }
                }
            }
            input:checked + label::before{
                border-color:$form_input_border_hover;
                background-color:$form_input_label_background;
            }
        }
    }
}

#map-canvas{
    border-color:$form_input_border;
}

/* BLOG */

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {background-color: $boutons_background_hover; border-color: $boutons_border_color_hover; color:$boutons_color_hover;}
.pagination > li:not(.active):hover > a, .pagination > li:not(.active):hover > span{background-color: $boutons_background_hover; border-color: $boutons_border_color_hover; color:$boutons_color_hover;}
.pagination > li:not(.active) > a, .pagination > li:not(.active) > span{background-color: $boutons_background; border-color: $boutons_border_color; color:$boutons_color;}
.pagination.pull-right li.active a{color:$boutons_color_hover;}

.apercu-article h2.titre-article, .apercu-article .h2.titre-article,
.single-article .titre-article h1, .single-article .titre-article .h1{
    background:$content_background;
}

/* FOOTER */

#footer {
    border-bottom-color: $footer_border;
    background:$footer_background;
    border-top-color: $footer_border;
    color:$footer_color;
    a, .mention a{
        color:$footer_color;
    }
}

/* PANIER */

.template-panier {
    &:not(.template-panier-4){
        background-color: $fond_barre_menu;
        color: $menu_color;
        border-color:$fond_barre_menu;
    }
    .connexion p {
        border-color: $panier-titre-color;
        &, a {
            color: $panier-titre-color;
        }
    }
    .count-live{
        background:$panier_count_background;
        color:$napier_count_color;
    }
    #header-panier {
        color: $panier-titre-color;
    }
    #panier #paniercontent {
        background-color: $panier-content-background;
        &, #paniertable {
            color: $panier-content-color;
        }
        border-color: $panier_background;
    }
    &.template-panier-1 {
        #panier #paniercontent {
            &, #paniertable {
                color: $panier-titre-color;
            }
        }
    }
    &.template-panier-2{
        background-color: $fond_barre_menu;
        color: $menu_color;
        border-color:$fond_barre_menu;
        .connexion p, .connexion p a, #header-panier{
            border:currentColor;
            color:$menu_color;
        }
    }
    &.template-panier-3{
        background-color:$fond_barre_menu;
        color:$menu_color;
        border-color:$fond_barre_menu;
        .connexion p, .connexion p a, #header-panier{
            border:currentColor;
            color:$menu_color;
        }
    }
    &.template-panier-4{
        #panier, .connexion{
            background-color: $fond_barre_menuS;
            color: $menuS_color;
            border-color:$fond_barre_menuS;
        }
        .connexion p, .connexion p a, #header-panier{
            border:currentColor;
            color:$menuS_color;
        }
    }
}

/* FLUX PANIER */

.button.button-secondary {
    background: $fp_bouton_background;

    &:hover {
        background: $fp_bouton_background_hover;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 {
    color:$fp_color;
    .side-tabs ul li{
        .step-done, &.active {
            background: $fp_bouton_background;
            border-color: $fp_bouton_background;

            a, span {
                color: $fp_bouton_color;
            }
            &::after{
                color: $fp_bouton_color;
            }
        }
    }
}
